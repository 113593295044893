@import "~bootstrap/scss/bootstrap";
@import "var";
@import "/public/dm/stylesheet";
@import "utils";
@import "star";
@import "filters";
@import "pie";
@import "buttons";
@import "box";
@import "chip";
@import "login";
@import "table";
@import "menu";

body {
  width: 100vw;
  overflow-x: hidden;
  background-color: $u_white;
  //padding-left: $side_width;
}

.container-fluid {
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto;
}

.overlayCustom {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 0 0 20px 20px;
  width: 100%;
}

.number-input {
  width: 50px
}

.container-settings {
  position: relative;
  margin-top: 80px;
}

.number {
  font-size: 9rem;
  font-weight: bold;
  color: $u_blue_dark
}

.title-valori {
  font-size: 2rem;
  font-weight: bold;
}


.page-title-bar {
  background: $u_white;
  border-top: 2px solid $u_black;
  border-bottom: 2px solid $u_black;
}
.page-title-bar-text {
  font-size: 1.4rem;
  font-weight: bold;
  padding-top: 12px;
  padding-bottom: 12px;
}

.page-footer {
  background: #ffffff;
}

.page-footer-text {
  font-size: 1.5rem;
  font-weight: bold;
  padding-top: 12px;
  padding-bottom: 12px;
}

.number-circle {
  font-size: 1.5rem;
  border: solid 3px $u_blue_dark;
  color: $u_blue_dark;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  &.selected {
    background: $u_blue_dark;
    color: $u_white;
  }
}

.pn-shadow {
  box-shadow: 0 -1px 20px 0 rgba(0, 0, 0, .16);
}

// fix for flickering of the tooltip on the dashboard piecharts
svg>g>g:last-child {
  pointer-events: none
}

.wrong .fa-check {
  display: block;
}

.good .fa-times {
  display: block;
}

.valid-feedback,
.invalid-feedback {
  margin-left: calc(2em + 0.25rem + 1.5rem);
}

.error-msg {
  white-space: pre-line;
}

.link {
  color: $u_blue;
}
